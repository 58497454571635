import React from 'react'
import { useSwiper, useSwiperSlide  } from 'swiper/react';

const Slide = ({video, parallaxAmount, parallaxOpacity}) => {
    const swiper = useSwiper();
    const swiperSlide = useSwiperSlide();
    const doRender = () => {
        return swiperSlide.isActive || swiperSlide.isPrev || swiperSlide.isNext
    }
    doRender();
  return (
      <>
      <div className="hero-slide">
            <div
              className="slide-image"
              data-swiper-parallax={parallaxAmount}
              data-swiper-parallax-opacity={parallaxOpacity}
            >
            <video
              controlsList={doRender() ? "" : "nodownload"}
              muted
              autoPlay
              loop
              playsInline
              className="h-full w-full object-cover fixed top-0 left-0 -z-50"
              src={doRender() ? video.video : undefined}>
            </video>
        </div>
       </div>
       </>
  )
}

export default Slide
